import { useContext, useEffect, useState } from "react";
import { UserType } from "../../interfaces/User";
import { getCurrentAgent } from "../../utils/statements/statement-builder";
import { sessionStore } from "../../contexts/SessionContext";
import { configStore } from "../../contexts/ConfigContext";
import { useUserId } from "../useUserInfo";
import * as localStorage from "../../utils/localStorage";
import useAthenaAPIClient from "../queries/useAthenaAPIClient/useAthenaAPIClient";
import { GlobalConfig, getLearningEntries } from "./useInitApp";
import { Config } from "../../interfaces/Config";
import { Objective } from "../../interfaces/Data";
import { useFeatureFlags } from "@evidenceb/athena-common/modules/FeatureFlags";
import { CacheNames } from "../../utils/sw-cache";

export default function useInitServiceWorker() {
    const athenaAPIClient = useAthenaAPIClient();
    const [isWorkerReady, setIsWorkerReady] = useState<boolean>(false);
    const featureFlags = useFeatureFlags();
    const userId = useUserId();
    const {
        session: { evidencebId },
    } = useContext(sessionStore);
    const {
        config: { declinaison },
    } = useContext(configStore);

    useEffect(() => {
        if (!featureFlags.offlineFeatures) return;

        if ("serviceWorker" in navigator && !isWorkerReady) {
            navigator.serviceWorker.ready.then((registration) => {
                console.log("🚀 ~ ATHENA SW READY registration:", registration);

                setIsWorkerReady(true);
            });
        }
    }, [isWorkerReady, featureFlags]);

    // these webservices are called before the service worker is initialized and activated
    // so we need a 2nd round of API calls that allows the service worker to put them in cache
    useEffect(() => {
        if (!featureFlags.offlineFeatures) return;

        async function isCachePopulated(): Promise<boolean> {
            const hasConfig = await caches.has(CacheNames.config);
            const hasUserInfo = await caches.has(CacheNames.userInfo);
            const hasStudent = await caches.has(CacheNames.userStudent);
            const hasTeacher = await caches.has(CacheNames.userTeacher);
            const hasQuestions = await caches.has(CacheNames.data);
            const hasStatements = await caches.has(CacheNames.statements);
            const hasMappingTree = await caches.has(CacheNames.mappingTree);
            const hasLearningSets = await caches.has(CacheNames.learningSets);
            return Promise.resolve(
                hasConfig &&
                    hasQuestions &&
                    hasStatements &&
                    hasUserInfo &&
                    (hasTeacher || hasStudent) &&
                    hasMappingTree &&
                    hasLearningSets
            );
        }

        async function triggerCachePopulation(): Promise<void> {
            let globalConfig: GlobalConfig;
            let tempGlobalconfig: Omit<GlobalConfig, "config"> & {
                config: Config;
            };
            const version = localStorage.getItem<string>(
                localStorage.Key.VERSION
            );
            let userType: UserType;
            athenaAPIClient.getTokenPayload().then((tokenPayload) => {
                if (
                    tokenPayload &&
                    tokenPayload.role === UserType.Student.toLowerCase()
                ) {
                    userType = UserType.Student;
                    athenaAPIClient.getStudent(userId);
                } else if (
                    tokenPayload &&
                    tokenPayload.role === UserType.Teacher.toLowerCase()
                ) {
                    userType = UserType.Teacher;
                    athenaAPIClient.getTeacher(userId);
                }
                athenaAPIClient.getResourcesLockStatus(userId, userType);
            });
            if (version) {
                globalConfig = await athenaAPIClient.getGlobalConfig(version);
                athenaAPIClient.getData(
                    version,
                    featureFlags["init-bandit-manchot-with-adaptive-test"]
                );
                // TEMP
                tempGlobalconfig = {
                    ...globalConfig,
                    config: {
                        ...globalConfig.config,
                        ai: globalConfig.config.ai,
                    },
                };
                if (featureFlags.requireMapping) {
                    athenaAPIClient.getMappingTree();
                    const learningEntries =
                        getLearningEntries(tempGlobalconfig);

                    if (learningEntries.length !== 0) {
                        let learningSet: { [key: string]: Objective } = {};
                        await Promise.all(
                            learningEntries.map(async (learningSetId) => {
                                const newLearningSet =
                                    await athenaAPIClient.getLearningSet(
                                        learningSetId!,
                                        // Depth of objectives, in which lay mapping information
                                        { depth: "3" }
                                    );
                                Object.assign(learningSet, newLearningSet);
                            })
                        );
                    }
                }
            }

            const agent = getCurrentAgent(declinaison, evidencebId);
            athenaAPIClient.getStatements(agent);
            // TODO: should we also cache fetchEvents?
        }

        async function initAfterServiceWorkerActivated() {
            const cacheIsPopulated = await isCachePopulated();
            if (!cacheIsPopulated) {
                if (isWorkerReady && !!userId) {
                    await triggerCachePopulation();
                }
            }
            return;
        }

        initAfterServiceWorkerActivated();
    }, [
        athenaAPIClient,
        declinaison,
        evidencebId,
        featureFlags,
        isWorkerReady,
        userId,
    ]);
}
