import { WorkModeType } from "../interfaces/Config";

export const workModeTypeToEventWorkmode = (workmode: WorkModeType) => {
    switch (workmode) {
        case WorkModeType.Duo:
            return "duo";
        case WorkModeType.SoloAI:
            return "solo-ai";
        case WorkModeType.Playlist:
            return "playlist";
        case WorkModeType.Tuto:
            return "tuto";
        case WorkModeType.Test:
            console.log(
                "WorkModeType.Test is not supported. Outputting instead 'tuto'."
            );
            return "tuto";
        case WorkModeType.Workshop:
            return "workshop";
        case WorkModeType.StandaloneAdaptiveTest:
            return "standalone-adaptive-test";
        case WorkModeType.Revision:
            return "revision";
        default:
            console.log(
                `${workmode} is not supported. Outputting instead 'tuto'.`
            );
            return "tuto";
    }
};

export const eventWorkmodeToWorkModeType = (
    eventWorkmode:
        | "solo-ai"
        | "revision"
        | "standalone-adaptive-test"
        | "tuto"
        | "duo"
        | "playlist"
        | "workshop"
): WorkModeType => {
    return {
        duo: WorkModeType.Duo,
        "solo-ai": WorkModeType.SoloAI,
        playlist: WorkModeType.Playlist,
        tuto: WorkModeType.Tuto,
        workshop: WorkModeType.Workshop,
        "standalone-adaptive-test": WorkModeType.StandaloneAdaptiveTest,
        revision: WorkModeType.Revision,
    }[eventWorkmode];
};
