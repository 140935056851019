import { useContext, useEffect } from "react";
import { sessionStore } from "../contexts/SessionContext";
import { AthenaEvent } from "@evidenceb/athena-event-storage-schemas";
import useOnlineStatus from "./useOnlineStatus";
import { useUserId } from "./useUserInfo";
import { v4 as uuid } from "uuid";
import { AthenaEventStream } from "@evidenceb/athena-events-tools";

export const usePingEvent = (athenaEventStream: AthenaEventStream) => {
    const {
        session: { sessionId, version },
    } = useContext(sessionStore);
    const isOnline = useOnlineStatus();
    const userId = useUserId();
    useEffect(() => {
        if (!userId) {
            return;
        }
        const id = setInterval(() => {
            const event: AthenaEvent<"session", "SessionPingEvent"> = {
                id: uuid(),
                createdAt: new Date().toISOString(),
                userId: userId,
                sessionId: sessionId,
                app: version,
                categoryData: {
                    online: isOnline,
                },
                type: "SessionPingEvent",
                data: {
                    url: window.location.href.split("?")[0],
                },
            };
            athenaEventStream.publish(event);
        }, 30000);
        return () => {
            clearInterval(id);
        };
    }, [athenaEventStream, userId, sessionId, isOnline, version]);
};
