const queryKeys = {
    all: ["playlists"] as const,
    resources: "resources" as const,
    assignments: "assignments" as const,

    getPlaylists: (params: { author_id: string }) =>
        [...queryKeys.all, queryKeys.resources, params] as const,
    getPlaylist: (id: string, params: { author_id: string }) =>
        [...queryKeys.all, queryKeys.resources, id, params] as const,
    getAssignments: (params: { teacher?: string; resource?: string }) =>
        [...queryKeys.all, queryKeys.assignments, params] as const,
    getPlaylistProgress: (params: { teacher_id: string }) =>
        [...queryKeys.all, "progress", params] as const,
};

export default queryKeys;
