import { useContext, useEffect, useRef } from "react";
import { LoaderStatus } from "../../interfaces/Status";
import { dataStore } from "../../contexts/DataContext";
import { cacheStore } from "../../contexts/CacheContext";
import { learningAssetsCacheStore } from "../../contexts/LearningAssetsCacheContext";
import { useFeatureFlag } from "@evidenceb/athena-common/modules/FeatureFlags";
import { configStore } from "../../contexts/ConfigContext";
import { sessionStore } from "../../contexts/SessionContext";

const useStartAssetsCache = (status: LoaderStatus | undefined) => {
    const { data } = useContext(dataStore);
    const {
        config: { apiUrls },
    } = useContext(configStore);
    const {
        session: { version },
    } = useContext(sessionStore);
    const { cacheState, dispatchCacheAction } = useContext(cacheStore);
    const { dispatchLearningAssetsCacheAction } = useContext(
        learningAssetsCacheStore
    );
    const offlineFeaturesFlag = useFeatureFlag("offlineFeatures");

    // Handle flags init effets
    const initRef = useRef<boolean>(false);
    useEffect(() => {
        if (
            status !== LoaderStatus.Success ||
            !offlineFeaturesFlag ||
            initRef.current
        )
            return;

        // TODO: Check new learning assets cache initialization ?

        dispatchCacheAction({
            type: "START_INIT",
            payload: {
                data,
                assetsUrl: apiUrls.endpoints.assetsProxy,
                version,
            },
        });

        dispatchLearningAssetsCacheAction({
            type: "ASSETS_CACHE_INIT",
            payload: {
                baseAssetsUrl: apiUrls.endpoints.assetsProxy,
                resourcesEndpoint: apiUrls.endpoints.resources!,
                version,
            },
        });

        initRef.current = true;
    }, [
        offlineFeaturesFlag,
        data,
        apiUrls,
        version,
        cacheState,
        dispatchCacheAction,
        dispatchLearningAssetsCacheAction,
        status,
    ]);
};
export default useStartAssetsCache;
