import React, { createContext, Dispatch } from "react";
import { CacheState, initialState } from "./infrastructure/cacheState";
import { CacheAction } from "./infrastructure/cacheAction";
import { CacheReducerWithMiddleware } from "./infrastructure/cacheRedux";

interface CacheContext {
    cacheState: CacheState;
    dispatchCacheAction: Dispatch<CacheAction>;
}

const cacheStore = createContext<CacheContext>({
    cacheState: initialState,
    dispatchCacheAction: () => null,
});

const { Provider } = cacheStore;
const CacheProvider = ({ children }: { children: React.ReactNode }) => {
    const [cacheState, dispatchCacheAction] = CacheReducerWithMiddleware();
    const value: CacheContext = {
        cacheState: cacheState as CacheState,
        dispatchCacheAction: dispatchCacheAction as React.Dispatch<CacheAction>,
    };
    return <Provider value={value}>{children}</Provider>;
};

export { cacheStore, CacheProvider };
