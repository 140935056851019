import { EventStorageClient } from "@evidenceb/athena-events-tools";
import { axiosClient } from "../utils/axios-client/axios-client";
import * as localStorage from "../utils/localStorage";
import { useContext } from "react";
import { configStore } from "../contexts/ConfigContext";

const useEventStorageClient = (): EventStorageClient | null => {
    const { config } = useContext(configStore);
    const eventStorageOrigin = config.apiUrls.endpoints.events
        ? new URL(config.apiUrls.endpoints.events).origin
        : null;
    return eventStorageOrigin
        ? new EventStorageClient(
              eventStorageOrigin,
              axiosClient.axios,
              // TODO: we could add to the axios instance above an interceptor
              // that automatically adds the auth header to requests. In that
              // case we wouldn't need this argument.
              () => localStorage.getItem<string>(localStorage.Key.TOKEN)!
          )
        : null;
};
export default useEventStorageClient;
