import { AIType } from "@evidenceb/ai-handler";
import { WorkModeType } from "../../interfaces/Config";
import { WorkmodesWithStatements } from "./types";

export const VERBS = {
    failed: {
        id: "http://adlnet.gov/expapi/verbs/failed",
        display: {
            "en-US": "failed",
        },
    },
    passed: {
        id: "http://adlnet.gov/expapi/verbs/passed",
        display: {
            "en-US": "passed",
        },
    },
    expressed: {
        id: "https://w3id.org/xapi/dod-isd/verbs/expressed",
        display: {
            "en-US": "expressed",
        },
    },
    "logged-in": {
        id: "https://w3id.org/xapi/adl/verbs/logged-in",
        display: {
            "en-US": "logged-in",
        },
    },
    accessed: {
        id: "https://w3id.org/xapi/netc/verbs/accessed",
        display: {
            "en-US": "accessed",
        },
    },
    completed: {
        id: "http://adlnet.gov/expapi/verbs/completed",
        display: {
            "en-US": "completed",
        },
    },
    viewed: {
        id: "http://id.tincanapi.com/verb/viewed",
        display: {
            "en-US": "viewed",
        },
    },
};

export const XAPI_REGISTRY = "http://xapiressource.prod.evidenceb-services.com";

export const ADAPTIVE_TEST_CATEGORY = `${XAPI_REGISTRY}/category/adaptive-test`;
export const AI_CATEGORIES: Record<AIType, string> = {
    [AIType.AdaptiveTest]: ADAPTIVE_TEST_CATEGORY,
    [AIType.CNEDAdaptiveTest]: ADAPTIVE_TEST_CATEGORY,
    [AIType.BanditManchot]: `${XAPI_REGISTRY}/category/bandit-manchot`,
    [AIType.RevisionManager]: `${XAPI_REGISTRY}/category/revisionAlg`,
};

export const WORKMODES_CATEGORIES: Record<WorkmodesWithStatements, string> = {
    [WorkModeType.SoloAI]: `${XAPI_REGISTRY}/category/solo-ai`,
    [WorkModeType.Revision]: `${XAPI_REGISTRY}/category/revision`,
    [WorkModeType.StandaloneAdaptiveTest]: `${XAPI_REGISTRY}/category/standalone-adaptive-test`,
    [WorkModeType.Playlist]: `${XAPI_REGISTRY}/category/playlist`,
};
