import React, { PropsWithChildren, useContext } from "react";
import { useIntl } from "react-intl";
import { useBreakpoints } from "@evidenceb/athena-common/helpers/breakpoints";
import { configStore } from "../../contexts/ConfigContext";
import { useSubjectChoice } from "../SubjectChoice/ChoiceSteps/api/useSubjectChoice";
import Card from "../../design-system-components/Card/Card";
import Error from "../../design-system-components/Error/Error";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import TabTitle from "../../components/TabTitle/TabTitle";
import AvatarChoice from "./AvatarChoice";
import Subject from "./Subject";
import { pageTitles } from "../../utils/messages";
import { subjectChoiceMessages } from "../SubjectChoice/messages";
import RouteWithHeaderWrapper from "../../components/RouteWithHeaderWrapper/RouteWithHeaderWrapper";
import { useAccountSections } from "./utils";
import useOnlineStatus from "../../hooks/useOnlineStatus";

import "./Account.scss";

const Account = () => {
    const [_, { isError }] = useSubjectChoice();
    const { isMobile } = useBreakpoints();
    const Container = isMobile ? "div" : Card;
    const accountSections = useAccountSections();
    const isOnline = useOnlineStatus();

    return (
        <Wrapper>
            <Container className="account-settings__parameters">
                {!isMobile && accountSections.subject && <Subject />}
                {accountSections.avatar && isOnline && <AvatarChoice />}
                {isMobile && accountSections.subject && <Subject />}
                {isError && <ErrorMessage />}
            </Container>
        </Wrapper>
    );
};
export default Account;

const Wrapper = ({ children }: PropsWithChildren) => {
    const intl = useIntl();
    const title = intl.formatMessage(pageTitles.accountSettings);
    return (
        <RouteWithHeaderWrapper>
            <TabTitle title={title} />
            <PageWrapper className="account-settings">
                <PageHeader title={title} />
                {children}
            </PageWrapper>
        </RouteWithHeaderWrapper>
    );
};

const ErrorMessage = () => {
    const intl = useIntl();
    const {
        config: { contact },
    } = useContext(configStore);
    return (
        <div className="account-settings__subject__error">
            <Error
                className="choice-steps__error"
                message={
                    <>
                        {intl.formatMessage(
                            subjectChoiceMessages.errorValidation
                        )}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={`mailto:${contact}`}
                        >
                            {intl.formatMessage(
                                subjectChoiceMessages.errorContactSupport
                            )}
                        </a>
                    </>
                }
                icon="warning"
                color="var(--element-teacher-default)"
            />
        </div>
    );
};
