import { defineMessages } from "react-intl";

export const messages = defineMessages({
    savingAthenaEventFailed: {
        id: "aesm-savingAthenaEventFailed",
        defaultMessage: "An error occurred while synchronizing your progress.",
    },
    savingAthenaEventSucceededAfterFailure: {
        id: "aesm-savingAthenaEventSucceededAfterFailure",
        defaultMessage: "Progress synchronization has resumed.",
    },
});
