import {
    AthenaEvent,
    AthenaEventCategory,
    AthenaEventType,
} from "@evidenceb/athena-event-storage-schemas";
import { AthenaEventStream } from "@evidenceb/athena-events-tools";
import React, { useContext, useMemo } from "react";
import { v4 as uuid } from "uuid";
import { sessionStore } from "./SessionContext";

const evs = new AthenaEventStream(async function globalErrorHandler(
    error,
    athenaEvent,
    subscriberId
): Promise<void> {
    console.group("Unhandled error thrown while processing an AthenaEvent");
    console.log("Error:", error);
    console.log("AthenaEvent:", athenaEvent);
    console.log("Subscriber id:", subscriberId);
    console.groupEnd();
    throw error; // need to buble up for sentry
});

export const EventStreamContext = React.createContext(evs);

export const useEventStream = () => {
    return useContext(EventStreamContext);
};

/**
 * Function publishing an AthenaEvent to the AthenaEventStream that
 * auto-populates some AthenaEvent properties so that the caller doesn't need to
 * supply them.
 */
export type AthenaEventStreamPublisher = <
    C extends AthenaEventCategory,
    T extends AthenaEventType
>(
    athenaEvent: Omit<
        AthenaEvent<C, T>,
        "id" | "createdAt" | "userId" | "sessionId" | "app"
    >
) => void;

export const useAthenaEventStreamPublisher = (): AthenaEventStreamPublisher => {
    const athenaEventStream = useContext(EventStreamContext);
    const { session } = useContext(sessionStore);
    return useMemo(
        () => (athenaEvent) =>
            athenaEventStream.publish({
                id: uuid(),
                createdAt: new Date().toISOString(),
                sessionId: session.sessionId,
                userId: session.userId,
                app: session.version,
                ...athenaEvent,
            }),
        [athenaEventStream, session]
    );
};

export function EventStreamProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <EventStreamContext.Provider value={evs}>
            {children}
        </EventStreamContext.Provider>
    );
}
