import { NavLink } from "react-router-dom";
import { Page, Pagetype } from "../../../interfaces/Config";
import { getUrl } from "../../../utils/dataRetrieval";
import { UserType } from "../../../interfaces/User";
import { defineMessages, useIntl } from "react-intl";
import { useUrl } from "../../../hooks/usePage";

export const isTeacherDashboardNavActive = (
    pages: Page[]
): Parameters<typeof NavLink>[0]["isActive"] => {
    const page = pages.find((page) => page.type === Pagetype.DASHBOARD);
    if (!page) return undefined;
    return (_, location) => {
        const url = getUrl(page, UserType.Teacher);
        return location.pathname.replace("/", "").startsWith(url);
    };
};

export const useDashboardRouter = () => {
    const intl = useIntl();
    const baseURL = useUrl(Pagetype.DASHBOARD);

    return {
        baseURL,
        classroomList: baseURL,
        singleClassroom: (classroomId: string) =>
            `${baseURL}/${intl.formatMessage(
                routeMessages.classrooms
            )}/${classroomId}`,
        studentDetails: (studentId: string, classroomId: string) =>
            `${baseURL}/${intl.formatMessage(
                routeMessages.student
            )}/${classroomId}/${studentId}`,
        progressionOverview: (classroomId: string, moduleId: string) =>
            `${baseURL}/${intl.formatMessage(
                routeMessages.classrooms
            )}/${intl.formatMessage(
                routeMessages.progression
            )}/${classroomId}/${moduleId}`,
        clustering: (classroomId: string, moduleId: string) =>
            `${baseURL}/${intl.formatMessage(
                routeMessages.classrooms
            )}/${classroomId}/${moduleId}/${intl.formatMessage(
                routeMessages.clusters
            )}`,
        createGroup: `${baseURL}/${intl.formatMessage(
            routeMessages.groups
        )}/create`,
        editGroup: (groupId: string) =>
            `${baseURL}/${intl.formatMessage(
                routeMessages.groups
            )}/edit/${groupId}`,
        groupDetails: (groupId: string) =>
            `${baseURL}/${intl.formatMessage(routeMessages.groups)}/${groupId}`,
        groupProgressionOverview: (groupId: string, moduleId: string) =>
            `${baseURL}/${intl.formatMessage(
                routeMessages.groups
            )}/${intl.formatMessage(
                routeMessages.progression
            )}/${groupId}/${moduleId}`,
        usersCodeDocument: (groupId: string) => `${baseURL}/pdf/${groupId}`,
    };
};

const routeMessages = defineMessages({
    classrooms: {
        id: "dashboard-paths-classes",
        defaultMessage: "classes",
    },
    student: {
        id: "dashboard-paths-student",
        defaultMessage: "student",
    },
    progression: {
        id: "dashboard-paths-progression",
        defaultMessage: "progression",
    },
    clusters: {
        id: "dashboard-paths-groups",
        defaultMessage: "groups",
    },
    groups: {
        // Warning: it's weird that groups is used both for clustering and
        // groups but it's a leftover from when it was once developed without
        // paying close attention
        id: "dashboard-paths-groups",
        defaultMessage: "groups",
    },
});
