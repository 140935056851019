import React, { useContext } from "react";
import { sessionStore } from "../../contexts/SessionContext";
import { EventStreamContext } from "../../contexts/EventStreamContext";
import PlaySingleExercise from "./PlaySingleExercise/PlaySingleExercise";
import WindowContextLoader from "./WindowContextLoader/WindowContextLoader";
import { featureFlagsStore } from "@evidenceb/athena-common/modules/FeatureFlags";
import * as localStorage from "./../../utils/localStorage";
import { isProductionEnvironment } from "../../utils/env";
import { Subscriber } from "@evidenceb/athena-events-tools";

/**
 * This components adds development functionalities to the specimen.
 */
const Dev = () => {
    const { setFeatureFlags } = useContext(featureFlagsStore);

    const { setSession } = useContext(sessionStore);
    const eventStream = useContext(EventStreamContext);
    /*
     * Log Exercise details feature
     */
    const enableLogExerciseDetails = () => {
        setSession((curr) => {
            return {
                ...curr,
                flags: {
                    ...curr.flags,
                    logExerciseDetails: true,
                },
            };
        });
    };

    const logEventStream = () => {
        const eventStreamLogger: Subscriber = {
            id: "eventStreamLogger",
            async processAthenaEvent(athenaEvent: any) {
                console.log("Received AthenaEvent:", athenaEvent);
            },
        };
        eventStream.registerSubscriber(eventStreamLogger);
    };

    const stopEventStreamLog = () => {
        eventStream.unregisterSubscriber("eventStreamLogger");
    };

    const attachEventStreamToWindow = () => {
        (window as any).eventStream = eventStream;
    };

    const detachEventStreamToWindow = () => {
        (window as any).eventStream = null;
    };

    (window as any).DEBUG_UTILS = {
        enableLogExerciseDetails,
        logEventStream,
        stopEventStreamLog,
        attachEventStreamToWindow,
        detachEventStreamToWindow,
    };

    /*
     * toggle a feature flag
     */
    const toggleFeature = (featureName: string, toggle: boolean) => {
        const storageFlags = localStorage.getItem<string[]>(
            localStorage.Key.DEV_FLAGS
        );
        if (!storageFlags && toggle)
            localStorage.setItem(localStorage.Key.DEV_FLAGS, [featureName]);
        else if (storageFlags && storageFlags.includes(featureName) && !toggle)
            localStorage.setItem(
                localStorage.Key.DEV_FLAGS,
                storageFlags.filter((item) => item !== featureName)
            );
        else if (storageFlags && !storageFlags.includes(featureName) && toggle)
            localStorage.setItem(localStorage.Key.DEV_FLAGS, [
                ...storageFlags,
                featureName,
            ]);

        setFeatureFlags((flags) => {
            return {
                ...flags,
                [featureName]: toggle,
            };
        });
    };
    if (!isProductionEnvironment(window.location.hostname)) {
        (window as any).DEBUG_UTILS.toggleFeature = toggleFeature;
    }

    return (
        <>
            <WindowContextLoader />
            <PlaySingleExercise />
        </>
    );
};

export default Dev;
