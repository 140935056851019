import React, { PropsWithChildren, useContext } from "react";
import Header from "../Header/Header";
import MobileNav from "../Header/MobileNav/MobileNav";
import { sessionStore } from "../../contexts/SessionContext";
import TabTitle, { Props as TabTitleProps } from "../TabTitle/TabTitle";
import OfflineBanner from "../../pages/OfflineBanner/OfflineBanner";

const RouteWithHeaderWrapper = ({ children }: PropsWithChildren) => {
    return (
        <div
            style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <BaseRouteWithHeaderWrapper>
                <div
                    style={{
                        flex: 1,
                        position: "relative",
                        overflow: "hidden",
                    }}
                >
                    {children}
                </div>
            </BaseRouteWithHeaderWrapper>
        </div>
    );
};
export default RouteWithHeaderWrapper;

export const BaseRouteWithHeaderWrapper = ({
    children,
    title,
    ...titleProps
}: PropsWithChildren<Partial<TabTitleProps>>) => {
    const {
        session: {
            flags: { displaySignIn },
        },
    } = useContext(sessionStore);
    return (
        <>
            {title && <TabTitle title={title} {...titleProps} />}
            {!displaySignIn && <Header />}
            <OfflineBanner />
            {children}
            {!displaySignIn && <MobileNav />}
        </>
    );
};
