import { useState } from "react";
import { useFeatureFlag } from "@evidenceb/athena-common/modules/FeatureFlags";
import { useUserType } from "../../hooks/useUserInfo";
import { useSubjects } from "../../hooks/useActiveSubject";
import { useUserExtra } from "../../hooks/queries/useUserExtra";
import { UserType } from "../../interfaces/User";
import { defineMessages, IntlShape } from "react-intl";

enum AccountSection {
    Avatar = "avatar",
    Subject = "subject",
}

export const useAccountSections = (): { [key in AccountSection]: boolean } => {
    const userType = useUserType();
    const hasMapping = useFeatureFlag("requireMapping");
    const subjects = useSubjects();
    const avatarFlag = useFeatureFlag("chooseAvatar");

    return {
        [AccountSection.Avatar]: avatarFlag,
        [AccountSection.Subject]:
            userType === UserType.Teacher && hasMapping && subjects.length >= 2,
    };
};

/**
 * handle case where the avatar character source becomes unavailable
 */
export function useAvatarCharacter():
    | {
          src: string;
          alt: string;
          onError: () => void;
      }
    | undefined {
    const [imageError, setImageError] = useState(false);
    function handleImageError() {
        setImageError(true);
    }
    const [{ avatarCharacter }] = useUserExtra();
    return imageError || !avatarCharacter
        ? undefined
        : {
              src: avatarCharacter.src,
              alt: avatarCharacter.alt,
              onError: handleImageError,
          };
}

export const backgroundColors = [
    { alias: "white", alt: "white", code: "#FFF" },
    { alias: "pink", alt: "pink", code: "rgb(var(--alert-down))" },
    {
        alias: "orange",
        alt: "orange",
        code: "var(--block-item1-selected-teacher)",
    },
    { alias: "purple", alt: "purple", code: "#AC9BFF" },
    { alias: "blue", alt: "blue", code: "#78ACFF" },
    { alias: "green", alt: "green", code: "#B1D366" },
];

export const getColorAlt = (intl: IntlShape, color: string) => {
    switch (color) {
        case "blue":
            return intl.formatMessage(messages.blue);
        case "white":
            return intl.formatMessage(messages.white);
        case "purple":
            return intl.formatMessage(messages.purple);
        case "pink":
            return intl.formatMessage(messages.pink);
        case "orange":
            return intl.formatMessage(messages.orange);
        case "green":
            return intl.formatMessage(messages.green);
        default:
            return color;
    }
};

const messages = defineMessages({
    white: {
        id: "color-white",
        defaultMessage: "white",
    },
    purple: {
        id: "color-purple",
        defaultMessage: "purple",
    },
    pink: {
        id: "color-pink",
        defaultMessage: "pink",
    },
    orange: {
        id: "color-orange",
        defaultMessage: "orange",
    },
    blue: {
        id: "color-blue",
        defaultMessage: "blue",
    },
    green: {
        id: "color-green",
        defaultMessage: "green",
    },
});
