import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { sessionStore } from "../../../contexts/SessionContext";
import { UserType } from "../../../interfaces/User";

/**
 * Developer component that allows you to play a single exercise by id from a
 * console command.
 */
const PlaySingleExercise: React.FC = () => {
    const history = useHistory();
    const {
        session: { userType },
    } = useContext(sessionStore);

    useEffect(() => {
        (window as any).playExercise = (id: string) => {
            if (userType !== UserType.Teacher) {
                console.error("You shouldn't be here");
                return;
            }

            Sentry.addBreadcrumb({
                category: "window.console",
                message: `Play single exercise ${id}`,
                level: Sentry.Severity.Info,
            });
            history.push(`/play/${id}`);
        };
    }, [history, userType]);

    return <></>;
};

export default PlaySingleExercise;
