import React from "react";
import { IntlShape, defineMessages } from "react-intl";
import { Data } from "../../../../../interfaces/Data";
import {
    HierarchyPath,
    TreeNode,
    hierarchyPathToIds,
} from "../../../../../components/TreeCheckboxGroup";
import { commonMessages } from "../../../../../utils/messages";
import { Activity, Module, Objective } from "@evidenceb/gameplay-interfaces";
import {
    getActivityById,
    getObjectiveById,
} from "../../../../../utils/dataRetrieval";
import capitalize from "lodash/capitalize";
import memoize from "lodash/memoize";
import {
    Group as SessionGroup,
    Student,
} from "../../../../../interfaces/Session";
import { Group as UserGroup } from "../../../../../interfaces/User";
import { fullName } from "../../../../../utils/format";
import { CURRENT_RESOURCE_PARTS, CreateURLParams } from "../const";
import { makeHierarchyPath } from "../../../../../components/TreeCheckboxGroup/utils";
import {
    Assignment,
    DraftAssignment,
    DraftPlaylist,
    EditableAssignment,
    EditablePlaylist,
    PlaylistItem,
    Playlist,
    MinimalPlaylistItem,
} from "../../type";
import { UseQueryResult } from "@tanstack/react-query";
import { PlaylistAction } from "./playlistReducer";
import { WithPartial } from "../../../../../utils/types";
import { UserAvatar } from "../../../../../components/Avatar/Avatar";
import { ResourcePath } from "../utils";
import { Workshop } from "../../../Workshop/types";
import { Tuto } from "../../../Tuto/type";
import { LearningSetTypes } from "../../../../../interfaces/AthenaResources";

const messages = defineMessages({
    playlistGenericTitle: {
        id: "playlist-generic-title",
        defaultMessage: "My playlist",
    },
});

export const prepareDataForTreeCheckboxGroup = memoize(
    (
        data: Data,
        otherResourcesFlag: boolean | undefined,
        intl: IntlShape
    ): Record<string, TreeNode> => {
        const exercise = intl.formatMessage(commonMessages.exercise);
        return {
            ...data.modules.reduce(
                (modules, mod) => ({
                    ...modules,
                    [mod.id]: makeBundleItem(mod, {
                        labelFormatter: moduleLabelFormatter,
                        readOnly: true,
                        idPrefix: otherResourcesFlag
                            ? undefined
                            : [LearningSetTypes.Module],
                    }),
                }),
                {} as Record<string, TreeNode>
            ),
            ...data.objectives.reduce(
                (objectives, obj) => ({
                    ...objectives,
                    [obj.id]: makeBundleItem(obj, {
                        labelFormatter: objectiveLabelFormatter(intl),
                    }),
                }),
                {} as Record<string, TreeNode>
            ),
            ...data.activities.reduce(
                (activities, act) => ({
                    ...activities,
                    [act.id]: makeBundleItem(act, {
                        labelFormatter: activityLabelFormatter(intl),
                    }),
                }),
                {} as Record<string, TreeNode>
            ),
            ...data.exercises.reduce(
                (exercises, { id }) => ({
                    ...exercises,
                    [id]: {
                        id,
                        label:
                            exercise.slice(0, 1).toUpperCase() +
                            exercise.slice(1),
                        labelFormatter: exerciseLabelFormatter,
                    },
                }),
                {} as Record<string, TreeNode>
            ),
        };
    }
);

const moduleLabelFormatter = (label: string, index: number) =>
    `${index + 1}. ${label}`;
const objectiveLabelFormatter =
    (intl: IntlShape) => (label: string, index: number) =>
        `${intl
            .formatMessage(commonMessages.objectiveShort)
            .slice(0, 1)
            .toUpperCase()}${index + 1}. ${label}`;
const activityLabelFormatter =
    (intl: IntlShape) => (label: string, index: number) =>
        `${intl
            .formatMessage(commonMessages.activityShort)
            .slice(0, 1)
            .toUpperCase()}${index + 1}. ${label}`;
const exerciseLabelFormatter = (label: string, index: number) =>
    `${label} ${index + 1}`;

const makeBundleItem = (
    data: Module | Objective | Activity,
    common?: Partial<TreeNode>
): TreeNode => ({
    ...common,
    id: data.id,
    label: data.title.short!,
    childrenIds:
        (data as Module).objectiveIds ??
        (data as Objective).activityIds ??
        (data as Activity).exerciseIds,
});

export const getActiveItemBottomSheetTitle = (
    node: HierarchyPath,
    indexes: HierarchyPath | undefined,
    data: Data,
    intl: IntlShape
): string => {
    const ids = hierarchyPathToIds(node);
    const indexNumbers = indexes
        ? hierarchyPathToIds(indexes).map((index) => +index)
        : undefined;

    const title: string[] = [];

    const modIndex = indexNumbers ? indexNumbers[0] + 1 : undefined;
    modIndex && title.push(`${modIndex}.`);

    const objIndex = indexNumbers ? indexNumbers[1] + 1 : undefined;
    title.push(
        `${intl
            .formatMessage(commonMessages.objective)
            .charAt(0)
            .toUpperCase()}${objIndex ?? ""}.`
    );

    if (hasActivity(node)) {
        const actIndex = indexNumbers ? indexNumbers[2] + 1 : undefined;
        title.push(
            `${intl
                .formatMessage(commonMessages.activity)
                .charAt(0)
                .toUpperCase()}${actIndex ?? ""}.`
        );

        if (isExercise(node)) {
            const exIndex = indexNumbers ? indexNumbers[3] + 1 : undefined;
            title.push(
                `${capitalize(intl.formatMessage(commonMessages.exercise))} ${
                    exIndex ?? ""
                }`
            );
        } else {
            const actTitle = getActivityById(ids[2], data).title.short!;
            title.push(actTitle);
        }
    } else {
        const objTitle = getObjectiveById(ids[1], data).title.short!;
        title.push(objTitle);
    }

    return title.join(" ");
};

const isExercise = (item: HierarchyPath) =>
    hierarchyPathToIds(item).length === 4;

const hasActivity = (item: HierarchyPath) =>
    hierarchyPathToIds(item).length >= 3;

export const prepareClassroomsForTreeCheckboxGroup = (
    classrooms:
        | Pick<SessionGroup | UserGroup, "id" | "name" | "students">[]
        | undefined
): Record<string, TreeNode> => {
    const nodes: Record<string, TreeNode> = {};
    classrooms?.forEach((classroom) => {
        if (classroom.students.length === 0) return;

        nodes[classroom.id] = {
            id: classroom.id,
            label: classroom.name,
            childrenIds: [...classroom.students.map((student) => student.id)],
        };

        classroom.students.forEach((student) => {
            if (typeof nodes[student.id] !== "undefined") return;

            const studentName = fullName(student, {
                capitalizeLastName: true,
                startWithLastName: true,
            });

            nodes[student.id] = {
                id: student.id,
                icon: <UserAvatar user={student} />,
                label: studentName,
            };
        });
    });
    return nodes;
};

export const hierarchyPathToIndexes = (
    path: HierarchyPath,
    nodes: Record<string, TreeNode> | undefined,
    rootChildrenIds: HierarchyPath[]
): HierarchyPath | undefined => {
    if (!nodes) return undefined;

    const ids = hierarchyPathToIds(path);
    const indexes = ids.map((id, index) => {
        let indexInParent: number;
        if (index === 0) {
            indexInParent = rootChildrenIds.findIndex(
                (rootNodeId) => rootNodeId === id
            );
        } else {
            const parent = nodes[ids[index - 1]];
            indexInParent = parent.childrenIds!.findIndex(
                (nodeId) => nodeId === id
            );
        }
        return `${indexInParent}`;
    });
    return makeHierarchyPath(...indexes);
};

export const getInitialActiveNodeFromURL = (
    urlParams: CreateURLParams
): HierarchyPath | undefined => {
    const path = getHierarchyPathFromURL(urlParams);
    if (path) return path;
    return undefined;
};

export const getHierarchyPathFromURL = (urlParams: CreateURLParams) =>
    makeHierarchyPath(...CURRENT_RESOURCE_PARTS.map((part) => urlParams[part]));

export const getInitialExpandedNodesFromURL = (
    urlParams: CreateURLParams
): HierarchyPath[] => {
    const ids = CURRENT_RESOURCE_PARTS.map((part) => urlParams[part])
        .filter((id) => !!id)
        .slice(0, -1); // don't expand the active node
    const paths = [];
    let currentPath = "";
    for (let id of ids) {
        currentPath = makeHierarchyPath(currentPath, id);
        paths.push(currentPath);
    }
    return paths;
};

export const studentHierarchyPathToObject = (
    studentPaths: HierarchyPath[],
    classrooms: SessionGroup[] = []
): Student[] => {
    const studentsData: Record<string, Student> = {};
    studentPaths.forEach((studentPath) => {
        const [classroomId, studentId] = hierarchyPathToIds(studentPath);
        if (studentsData[studentId]) return;
        studentsData[studentId] = classrooms
            .find((classroom) => classroom.id === classroomId)!
            .students.find((student) => student.id === studentId)!;
    });
    return Object.values(studentsData);
};

export const getDefaultPlaylistTitle = (
    intl: IntlShape,
    playlistCount: number
) => {
    return `${intl.formatMessage(messages.playlistGenericTitle)} ${
        playlistCount + 1
    }`;
};

export const convertDraftToPlaylist = (
    {
        name,
        ...draft
    }: Omit<DraftPlaylist<ResourcePath | MinimalPlaylistItem>, "dirty">,
    data: Data
): WithPartial<EditablePlaylist<MinimalPlaylistItem>, "learning_set_id"> => {
    if (typeof name === "undefined")
        throw new Error("Playlist name cannot be empty");
    return {
        ...draft,
        name,
        learning_items: draft.learning_items.map(itemPathToLearningItem(data)),
        learning_set_type: "playlist",
        learning_set_context: undefined,
        learning_sets: undefined,
        learning_set_tags: undefined,
    };
};

const itemPathToLearningItem =
    (data: Data) =>
    (item: ResourcePath | MinimalPlaylistItem): MinimalPlaylistItem => {
        if ("learning_item_id" in item) return item;
        return item.toPlaylistLearningItem(data);
    };

export const convertDraftToAssignment = (
    draft: DraftAssignment
): Omit<EditableAssignment, "id"> => {
    if (draft.students.length === 0)
        throw new Error("There must be at least one student");
    return {
        ...draft,
        assigned_resource: {
            ...draft.assigned_resource,
            resource_type: "playlist",
        },
    };
};

export const pathToStudentId = (path: string) => hierarchyPathToIds(path)[1];

export const pathToClassroomId = (path: string) => path;

export const getInitPlaylistAction = (
    existingPlaylistId: string | undefined,
    existingPlaylistResult: UseQueryResult<
        Playlist<PlaylistItem> | undefined,
        unknown
    >,
    playlistsQuery: UseQueryResult<Playlist<PlaylistItem>[], unknown>,
    resources: { data: Data; collections: (Tuto | Workshop)[] },
    intl: IntlShape
): PlaylistAction => {
    if (existingPlaylistId) {
        if (existingPlaylistResult.data) {
            return {
                type: "set_playlist",
                playlist: {
                    ...playlistToDraft(existingPlaylistResult.data),
                    dirty: false,
                },
            };
        } else {
            throw new Error();
        }
    } else {
        let defaultName: string;
        if (playlistsQuery.isError)
            //TODO we let the user continue the creation but we want to warn them.
            defaultName = getDefaultPlaylistTitle(intl, 1);
        else
            defaultName = getDefaultPlaylistTitle(
                intl,
                playlistsQuery.data?.length ?? 0
            );
        return { type: "update_name", name: defaultName };
    }
};

export const initCheckedStudentsWithAssignment = (
    assignment: Assignment,
    classrooms: SessionGroup[] | undefined
) => {
    return assignment.students.map((student) => {
        const classroom = classrooms?.find((classroom) =>
            classroom.students.some((stud) => stud.id === student.id)
        );
        if (!classroom) throw new Error("Student not found");
        return `${classroom.id}/${student.id}`;
    });
};

export const playlistToDraft = (
    data: Playlist<PlaylistItem>
): Omit<DraftPlaylist, "dirty"> => ({
    learning_set_id: data.learning_set_id,
    author_id: data.author_id,
    name: data.name,
    description: data.description,
    status: data.status,
    learning_items: data.learning_items.map((item) =>
        ResourcePath.fromLearningItem(item)
    ),
});

export const isPlaylistNameUnique = (
    allPlaylists: Playlist<any>[] | undefined,
    playlist: DraftPlaylist
): boolean =>
    allPlaylists?.every(
        (existingPlaylist) =>
            existingPlaylist.name !== playlist.name ||
            existingPlaylist.learning_set_id === playlist.learning_set_id
    ) ?? true;
