import { Icon } from "@evidenceb/athena-common/design-system/Icon";
import {
    AthenaEventSaver,
    AthenaEventStream,
    EventStorageClient,
    Subscriber,
} from "@evidenceb/athena-events-tools";
import * as Sentry from "@sentry/react";
import safeStringify from "fast-safe-stringify";
import last from "lodash/last";
import throttle from "lodash/throttle";
import React, { useEffect } from "react";
import { IntlShape } from "react-intl";
import { toast } from "react-toastify";
import { messages } from "../../utils/athenaEventSaverMessages";

/**
 * Instantiates an AthenaEventSaver and registers an AthenaEventStream
 * subscriber that saves AthenaEvents to it. The created AthenaEventSaver is
 * paused and resumed when the app goes offline and back online.
 */
export default function useAthenaEventSaver(
    name: string,
    athenaEventTypeFilter: Subscriber["athenaEventTypeFilter"],
    athenaEventStream: AthenaEventStream,
    eventStorageClient: EventStorageClient | null,
    intl: IntlShape,
    options?: {
        maxSaveBufferSize?: number;
        maxSaveDelayMs?: number;
        showToastOnSaveAttemptFailures?: boolean;
        logSaveAttemptFailures?: boolean;
    }
) {
    useEffect(() => {
        if (!eventStorageClient) {
            return;
        }
        const athenaEventSaver = new AthenaEventSaver(eventStorageClient, {
            onSaveFailed(athenaEvent, saveErrors) {
                Sentry.captureException(
                    new Error(`${name}: AthenaEvent save failed`),
                    {
                        extra: {
                            athenaEvent: safeStringify(athenaEvent),
                            latestSaveError: safeStringify(last(saveErrors)),
                        },
                    }
                );
                notifySaveFailure(
                    intl,
                    options?.showToastOnSaveAttemptFailures ?? false
                );
            },
            onSaveAttemptFailed(athenaEvent, saveError) {
                if (options?.logSaveAttemptFailures) {
                    Sentry.captureException(
                        new Error(`${name}: AthenaEvent save attempt failed`),
                        {
                            extra: {
                                athenaEvent: safeStringify(athenaEvent),
                                latestSaveError: safeStringify(saveError),
                            },
                        }
                    );
                }
                notifySaveFailure(
                    intl,
                    options?.showToastOnSaveAttemptFailures ?? false
                );
            },
            onSaveAttemptSucceededAfterFailure() {
                notifySaveSuccess(
                    intl,
                    options?.showToastOnSaveAttemptFailures ?? false
                );
            },
            maxSaveBufferSize: options?.maxSaveBufferSize,
            maxSaveDelayMs: options?.maxSaveDelayMs,
        });
        athenaEventStream.registerSubscriber({
            id: name,
            athenaEventTypeFilter,
            async processAthenaEvent(athenaEvent) {
                athenaEventSaver.saveAthenaEvent(athenaEvent);
            },
        });
        return () => {
            athenaEventStream.unregisterSubscriber(name);
        };
    }, [
        name,
        athenaEventTypeFilter,
        athenaEventStream,
        eventStorageClient,
        intl,
        options,
    ]);
}

const toastGracePeriodMs = 16_000;
const throttledFailureToast = throttle(toast, toastGracePeriodMs, {
    trailing: false,
});
const throttledSuccessToast = throttle(toast, toastGracePeriodMs, {
    trailing: false,
});

function notifySaveFailure(intl: IntlShape, notificationEnabled: boolean) {
    if (notificationEnabled) {
        throttledFailureToast(
            intl.formatMessage(messages.savingAthenaEventFailed),
            {
                containerId: "athena-event-saving-failure-notifier",
                autoClose: false,
                className: "failure",
                icon: React.createElement(Icon, {
                    path: "warning_fill",
                }),
            }
        );
    }
}

function notifySaveSuccess(intl: IntlShape, notificationEnabled: boolean) {
    if (notificationEnabled) {
        throttledSuccessToast(
            intl.formatMessage(messages.savingAthenaEventSucceededAfterFailure),
            {
                containerId: "athena-event-saving-failure-notifier",
                autoClose: 8_000,
                className: "success",
                icon: React.createElement(Icon, {
                    path: "negative_checkmark",
                }),
            }
        );
    }
}
