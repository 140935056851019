import {
    LegacyTheme,
    Theme,
    ThemeColors,
    ThemeType,
    themeColors,
} from "../../interfaces/Theme";
import { lightTheme } from "./theme-light";
import { darkTheme } from "./theme-dark";
import { defaultColors } from "./defaultColors";
import { Config } from "../../interfaces/Config";
import Case from "case";
import { chatbotTheme } from "./chatbot-theme";

export function injectCss(css: string) {
    const style = document.createElement("style");
    // The following line is required for this to work on WebKit
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    style.sheet!.insertRule(css, 0);
}

export const applyThemeWithLegacy = ({
    type,
    ...mixedTokens
}: Config["theme"]) => {
    const legacyTokens: Partial<LegacyTheme> = {};
    const tokens: Partial<ThemeColors> = {};

    Object.entries(mixedTokens).forEach(([token, value]) => {
        if ((themeColors as ReadonlyArray<string>).includes(token))
            tokens[token as keyof ThemeColors] = value as string;
        else legacyTokens[token as keyof LegacyTheme] = value as any;
    });
    applyLegacyCSS(legacyTokens);
    applyTheme({ type, ...tokens });
};

export const applyTheme = (
    { type = ThemeType.Light, ...customTokens }: Theme,
    selector: string = ":root"
) => {
    const baseTokens = type === ThemeType.Light ? lightTheme : darkTheme;
    const tokens = { ...baseTokens, ...customTokens };
    const variables = Object.entries(tokens)
        .map(([tokenCamel, value]) => `--${Case.kebab(tokenCamel)}: ${value};`)
        .join("");
    injectCss(`${selector} {${variables}}`);
};

export function applyLegacyCSS(theme: Partial<LegacyTheme>) {
    const useCustomColors = !!theme.customColors;

    const chatbot: LegacyTheme["chatbot"] = theme.chatbot ?? chatbotTheme!;
    console.log("inject", defaultColors.colorCorrect, theme.colorCorrect);
    injectCss(`
        :root {
            --color-primary: ${theme.colorPrimary};
            --color-primary-light: ${theme.colorPrimaryLight};
            --color-secondary: ${theme.colorSecondary};
            --color-correct: ${defaultColors.colorCorrect};
            --color-incorrect: ${theme.colorError ?? defaultColors.colorError};
            --color-alert: ${theme.colorAlert};

            /* Typographical chart for gameplays */
            --color-editor-one: #CA411D;
            --color-editor-two: #155598;
           
            --chatbot-color-bot-bubble: ${chatbot.botBubbleColor};
            --chatbot-color-bot-bubble-opacity-60: ${chatbot.botBubbleColor}60;
            --chatbot-color-bot-bubble-correct: ${
                chatbot.botBubbleColorCorrect ?? defaultColors.colorCorrect
            };
            --chatbot-color-bot-bubble-incorrect: ${
                chatbot.botBubbleColorIncorrect ?? defaultColors.colorError
            };
            --chatbot-color-bot-text-correct: ${
                chatbot.textCorrect ?? "var(--font-black)"
            };
            --chatbot-color-bot-text-incorrect: ${
                chatbot.textIncorrect ?? defaultColors.colorError
            };
            --chatbot-color-background-correct: ${
                chatbot.backgroundColorCorrect ?? defaultColors.colorCorrect
            };
            --chatbot-color-background-incorrect: ${
                chatbot.backgroundColorIncorrect ?? defaultColors.colorError
            };
            --chatbot-color-bot-bubble-feedback: ${
                chatbot.botBubbleColorFeedback
            };
            --chatbot-color-user-bubble: ${chatbot.userBubbleColorInteractive};
            --chatbot-color-user-bubble: ${
                chatbot.userBubbleColorNonInteractive
            };
            --chatbot-color-disabled-bg: ${chatbot.disabledBg};
            --chatbot-color-disabled-txt: ${chatbot.disabledText};
            --chatbot-color-validate-btn: ${chatbot.validateBg};
               
            ${
                useCustomColors && theme.customColors?.playerHeaderBg
                    ? `--custom-color-player-header-bg: ${theme.customColors.playerHeaderBg};`
                    : ""
            }
            ${
                useCustomColors && theme.customColors?.playerHeaderTxt
                    ? `--custom-color-player-header-txt: ${theme.customColors.playerHeaderTxt};`
                    : ""
            }
            ${
                useCustomColors && theme.customColors?.playerHeaderBottomBorder
                    ? `--custom-color-player-header-bottom-border: ${theme.customColors.playerHeaderBottomBorder};`
                    : ""
            }
            ${
                useCustomColors && theme.customColors?.footerBg
                    ? `--custom-color-footer-bg: ${theme.customColors.footerBg};`
                    : ""
            }
            ${
                useCustomColors && theme.customColors?.footerTxt
                    ? `--custom-color-footer-txt: ${theme.customColors.footerTxt};`
                    : ""
            }
            ${
                useCustomColors && theme.customColors?.headerTxt
                    ? `--custom-color-header-txt: ${theme.customColors.headerTxt};`
                    : ""
            }
        }
    `);
    if (theme.contentPagesStyle) addContentPageCss(theme.contentPagesStyle);
}

export function addContentPageCss(customCss: string) {
    const style = document.createElement("style");
    style.appendChild(document.createTextNode(customCss));
    document.head.appendChild(style);
}
