import { useContext, useMemo } from "react";
import { configStore } from "../../../../contexts/ConfigContext";
import { axiosClient } from "../../../../utils/axios-client/axios-client";
import * as localStorage from "../../../../utils/localStorage";
import {
    Assignment,
    Playlist,
    DraftPlaylist,
    DraftAssignment,
    PlaylistItem,
    EditableAssignment,
    PlaylistProgress,
    MinimalPlaylistItem,
} from "../type";
import { QueryFunctionContext } from "@tanstack/react-query";
import queryKeys from "./queryKeys";
import {
    convertDraftToAssignment,
    convertDraftToPlaylist,
} from "../Teacher/EditPlaylist/utils";
import { dataStore } from "../../../../contexts/DataContext";
import { useFeatureFlag } from "@evidenceb/athena-common/modules/FeatureFlags";
import { ResourcePath } from "../Teacher/utils";

const usePlaylistAPIClient = () => {
    const {
        config: { apiUrls },
    } = useContext(configStore);
    const { data } = useContext(dataStore);
    const notificationsFlag = useFeatureFlag("notifications");

    const playlistAPIClient = useMemo(() => {
        const headers = {
            Authorization: `Bearer ${localStorage.getItem<string>(
                localStorage.Key.TOKEN
            )}`,
            "x-evidenceb-app": "athena",
            "Content-Type": "application/json",
            "x-evb-origin": window.location.origin,
        };

        return {
            async postPlaylist(
                draft: Omit<
                    DraftPlaylist<ResourcePath | MinimalPlaylistItem>,
                    "dirty"
                >
            ): Promise<Playlist<PlaylistItem>> {
                const playlist = convertDraftToPlaylist(draft, data);
                const res = await axiosClient.axios.post<
                    Playlist<PlaylistItem>
                >(`${apiUrls.endpoints.resources!}/playlists`, playlist, {
                    headers,
                });
                return res.data;
            },

            async putPlaylist(
                draft: DraftPlaylist
            ): Promise<Playlist<PlaylistItem>> {
                const playlist = convertDraftToPlaylist(draft, data);
                const res = await axiosClient.axios.put<Playlist<PlaylistItem>>(
                    `${apiUrls.endpoints.resources!}/playlists/${
                        playlist.learning_set_id
                    }?author_id=${playlist.author_id}`,
                    playlist,
                    {
                        headers,
                    }
                );
                return res.data;
            },

            async putPlaylistStatus(
                playlist: Pick<
                    Playlist,
                    "learning_set_id" | "author_id" | "status"
                >
            ): Promise<Playlist<PlaylistItem>> {
                const res = await axiosClient.axios.put<Playlist<PlaylistItem>>(
                    `${apiUrls.endpoints.resources!}/playlists/${
                        playlist.learning_set_id
                    }?author_id=${playlist.author_id}`,
                    playlist,
                    {
                        headers,
                    }
                );
                return res.data;
            },

            async getPlaylists(
                context: QueryFunctionContext<
                    ReturnType<(typeof queryKeys)["getPlaylists"]>
                >
            ) {
                const params: { author_id: string } = context.queryKey[2];
                const res = await axiosClient.axios.get<
                    Playlist<PlaylistItem>[]
                >(
                    `${apiUrls.endpoints.resources!}/playlists?author_id=${
                        params.author_id
                    }`,
                    { headers }
                );
                return res.data;
            },

            async getPlaylist(
                context: QueryFunctionContext<
                    ReturnType<(typeof queryKeys)["getPlaylist"]>
                >
            ) {
                const playlistId: string = context.queryKey[2];
                const params: { author_id: string } = context.queryKey[3];
                const searchParams = new URLSearchParams(params).toString();
                const res = await axiosClient.axios.get<Playlist<PlaylistItem>>(
                    `${apiUrls.endpoints
                        .resources!}/playlists/${playlistId}?${searchParams}`,
                    { headers }
                );
                return res.data;
            },

            async postAssignment(draft: DraftAssignment): Promise<Assignment> {
                const searchParams = new URLSearchParams({
                    notify: `${notificationsFlag}`,
                }).toString();
                const assignment = convertDraftToAssignment(draft);
                const res = await axiosClient.axios.post<Assignment>(
                    `${apiUrls.endpoints
                        .users!}/api/resource-assignments/?${searchParams}`,
                    assignment,
                    { headers: { Authorization: headers.Authorization } }
                );
                return res.data;
            },

            async getAssignments(
                context: QueryFunctionContext<
                    ReturnType<(typeof queryKeys)["getAssignments"]>
                >
            ): Promise<Assignment[]> {
                const params: { teacher?: string; resource?: string } =
                    context.queryKey[2];
                const searchParams = new URLSearchParams(params).toString();
                const res = await axiosClient.axios.get<Assignment[]>(
                    `${apiUrls.endpoints
                        .users!}/api/resource-assignments/?${searchParams}`,
                    { headers: { Authorization: headers.Authorization } }
                );
                return res.data;
            },

            async updateAssignment(assignment: EditableAssignment) {
                const searchParams = new URLSearchParams({
                    notify: `${notificationsFlag}`,
                }).toString();
                const { id, ...newAssignment } = assignment;
                const res = await axiosClient.axios.put<Assignment>(
                    apiUrls.endpoints.users! +
                        `/api/resource-assignments/${id}/?${searchParams}`,
                    newAssignment,
                    { headers: { Authorization: headers.Authorization } }
                );
                return res.data;
            },

            async getPlaylistProgress(
                context: QueryFunctionContext<
                    ReturnType<(typeof queryKeys)["getPlaylistProgress"]>
                >
            ) {
                const params: { teacher_id: string } = context.queryKey[2];
                const res = await axiosClient.axios.get<PlaylistProgress[]>(
                    `${apiUrls.endpoints.analytics!}/playlists?teacher_id=${
                        params.teacher_id
                    }`,
                    { headers }
                );
                return res.data;
            },
        };
    }, [apiUrls, data, notificationsFlag]);

    return playlistAPIClient;
};

export default usePlaylistAPIClient;
