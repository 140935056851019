import { useContext } from "react";
import { configStore } from "../contexts/ConfigContext";
import { sessionStore } from "../contexts/SessionContext";
import { Pagetype } from "../interfaces/Config";
import { getUrl } from "../utils/dataRetrieval";

export const useUrlIfExists = (pageType: Pagetype): string | undefined => {
    const {
        config: { pages },
    } = useContext(configStore);
    const {
        session: { userType },
    } = useContext(sessionStore);

    const page = pages.find((page) => page.type === pageType);
    if (!page) return undefined;
    return `/${getUrl(page, userType)}`;
};

export const useUrl = (pageType: Pagetype): string => {
    return useUrlIfExists(pageType) ?? "/";
};

export const usePageTitle = (
    title: string,
    opts?: Parameters<typeof useGetPageTitle>[0]
): string => {
    const getPageTitle = useGetPageTitle(opts);
    return getPageTitle(title);
};

export const useGetPageTitle = (opts?: {
    omitClientName?: boolean;
    reverse?: boolean;
}): ((title: string) => string) => {
    const {
        config: { client_name },
    } = useContext(configStore);
    if (opts?.omitClientName) return (title: string) => title;
    if (opts?.reverse) return (title: string) => `${client_name} - ${title}`;
    return (title: string) => `${title} - ${client_name}`;
};
