import React, { useContext, useEffect, lazy, Suspense } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import appConfig from "../config";
import Error from "../pages/Error/Error";
import Loader from "../components/Loader/Loader";
import Dev from "../components/Dev/Dev";
import { configStore } from "../contexts/ConfigContext";
import { sessionStore } from "../contexts/SessionContext";
import { errorStore } from "../contexts/ErrorContext";
import useInitApp from "../hooks/useInitApp/useInitApp";
import { LoaderStatus } from "../interfaces/Status";
import { UserType } from "../interfaces/User";
import useAthenaAPIClient from "../hooks/queries/useAthenaAPIClient/useAthenaAPIClient";
import pRetry from "p-retry";
import { AuthMode } from "../interfaces/Config";
import ErrorModal from "../components/ErrorModal/ErrorModal";
import { Icon } from "@evidenceb/athena-common/design-system/Icon";
import { makeLoggedInStatement } from "../utils/statements/statement-builder";
import { BASE_ROUTE as AUTH_ROUTE } from "../pages/Authentication/routes";
import { ToastContainer, toast } from "react-toastify";
import Account from "../pages/Account/Account";
import { setActiveSubjectCSSVariablesOnBody } from "../utils/styles";
import { PLACEHOLDER_SUBJECT, useSubjects } from "../hooks/useActiveSubject";
import { ROUTES } from "./routing";
import { useAccountSections } from "../pages/Account/utils";
import { defineMessages, useIntl } from "react-intl";
import { useEventStream } from "../contexts/EventStreamContext";
import { AthenaEvent } from "@evidenceb/athena-event-storage-schemas";
import { v4 as uuid } from "uuid";
import useOnlineStatus from "../hooks/useOnlineStatus";
import { getPlatformDetails } from "../utils/platform";
import { usePingEvent } from "../hooks/usePingEvent";
import { useUserExtra } from "../hooks/queries/useUserExtra";
import { useUserType } from "../hooks/useUserInfo";

import "react-toastify/dist/ReactToastify.css";
import "./Root.scss";

const StudentRouter = lazy(() => import("./StudentRouter"));
const TeacherRouter = lazy(() => import("./TeacherRouter"));
const Authentication = lazy(
    () => import("../pages/Authentication/Authentication")
);

function Root() {
    const { config } = useContext(configStore);
    const { errorInfo } = useContext(errorStore);
    const { status, setStatus } = useInitApp();
    const userType = useUserType();
    const intl = useIntl();
    const accountSections = useAccountSections();
    const showAccount = Object.values(accountSections).some((show) => show);
    const eventStream = useEventStream();

    useEffect(() => {
        if (errorInfo.page) setStatus(LoaderStatus.Error);
    }, [errorInfo, setStatus]);

    useSendLoginStatement(status);
    usePingEvent(eventStream);

    useStylesSetup();

    return (
        <Router basename={appConfig.basePath}>
            <Suspense fallback={<Loader isLoading />}>
                <div className="app-root">
                    {userType === UserType.Student && (
                        <StudentRouter
                            isLoading={status !== LoaderStatus.Success}
                        />
                    )}
                    {userType === UserType.Teacher && (
                        <TeacherRouter
                            isLoading={status !== LoaderStatus.Success}
                        />
                    )}

                    <Loader
                        isLoading={status === LoaderStatus.Loading}
                        loadingMessage={intl.formatMessage(
                            messages.loadingApplication
                        )}
                        className="app-root"
                    >
                        {status === LoaderStatus.Success ? (
                            <>
                                <Dev />
                                <Switch>
                                    <Route path={`/${AUTH_ROUTE}/`}>
                                        <Authentication />
                                    </Route>
                                    {config.auth.mode ===
                                        AuthMode.Authentication && (
                                        <Redirect to={`/${AUTH_ROUTE}/`} />
                                    )}

                                    <Route path="/error">
                                        <Error />
                                    </Route>

                                    {showAccount && (
                                        <Route path={`/${ROUTES.account}`}>
                                            <Account />
                                        </Route>
                                    )}
                                </Switch>
                            </>
                        ) : status === LoaderStatus.Error ? (
                            errorInfo.page && <Error />
                        ) : null}
                    </Loader>
                </div>

                {/** Init Error modal */}
                <ErrorModal />

                <div>
                    <ToastContainer
                        className="toast-action-feedback"
                        enableMultiContainer
                        containerId={"action-feedback"}
                        position={toast.POSITION.BOTTOM_CENTER}
                        closeButton={
                            <button>
                                <Icon
                                    path="close"
                                    color="var(--text-main-default)"
                                />
                            </button>
                        }
                        hideProgressBar
                    />
                    <ToastContainer
                        className="toast-progress-notifier"
                        enableMultiContainer
                        containerId={"progress-notifier"}
                        position={toast.POSITION.TOP_RIGHT}
                        theme="light"
                    />
                    <ToastContainer
                        className="toast-athena-event-saving-failure-notifier"
                        enableMultiContainer
                        containerId={"athena-event-saving-failure-notifier"}
                        position={toast.POSITION.BOTTOM_CENTER}
                        theme="light"
                    />
                </div>
            </Suspense>
        </Router>
    );
}
export default Root;

const messages = defineMessages({
    loadingApplication: {
        id: "loadingApp",
        defaultMessage:
            "You are loading an EvidenceB application. Please wait.",
    },
});

const useSendLoginStatement = (status: LoaderStatus | undefined) => {
    const {
        session: { evidencebId, sessionId },
    } = useContext(sessionStore);
    const { session } = useContext(sessionStore);
    const athenaAPIClient = useAthenaAPIClient();
    const { config } = useContext(configStore);
    const isOnline = useOnlineStatus();
    const eventStream = useEventStream();

    useEffect(() => {
        if (
            status !== LoaderStatus.Success ||
            !config.features?.additionalStatements?.studentLogIn
        )
            return;
        const statement = makeLoggedInStatement(
            config.declinaison,
            evidencebId,
            sessionId
        );
        pRetry(
            () => {
                return athenaAPIClient.postStatement(statement);
            },
            { retries: 5 }
        );

        const { browserName, fullVersion, OSName, isTouchDevice } =
            getPlatformDetails();

        const event: AthenaEvent<"session", "SessionLoginEvent"> = {
            id: uuid(),
            createdAt: new Date().toISOString(),
            userId: evidencebId,
            sessionId: sessionId,
            app: session.version,
            categoryData: {
                online: isOnline,
            },
            type: "SessionLoginEvent",
            data: {
                app: session.version ?? "athena", // get app in format 'athena/variation/branch/version'
                browser: {
                    name: browserName,
                    version: fullVersion,
                },
                lang: navigator.language,
                os: {
                    name: OSName,
                    version: "-1",
                },
                touchDevice: isTouchDevice,
            },
        };
        eventStream.publish(event);
    }, [
        status,
        config.declinaison,
        evidencebId,
        session.version,
        sessionId,
        eventStream,
        athenaAPIClient,
        config.features,
        isOnline,
    ]);
};

const useStylesSetup = () => {
    // Set variable to handle mobile responsivity
    useEffect(() => {
        const resizeVh = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
        };
        resizeVh();
        window.addEventListener("resize", resizeVh);
        return () => {
            window.removeEventListener("resize", resizeVh);
        };
    }, []);

    const subjects = useSubjects();
    const [extra] = useUserExtra();
    useEffect(() => {
        if (extra.activeSubject && subjects[0].id !== PLACEHOLDER_SUBJECT.id) {
            const activeSubjectObj = subjects.find(
                (subject) => subject.id === extra.activeSubject
            );
            setActiveSubjectCSSVariablesOnBody(activeSubjectObj);
        }
    }, [extra.activeSubject, subjects]);
};
