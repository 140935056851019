import React, {
    PropsWithChildren,
    isValidElement as isReactNode,
    ReactNode,
    useRef,
} from "react";
import cn from "classnames";

import { useBreakpoints } from "@evidenceb/athena-common/helpers/breakpoints";
import { VisuallyHidden } from "@evidenceb/athena-common/ui/VisuallyHidden";
import { Icon } from "@evidenceb/athena-common/design-system/Icon";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { commonMessages } from "../../utils/messages";
import useHideOnScroll from "../../hooks/ui/useHideOnScroll";

import "./PageHeader.scss";
import UserTypeSwitch from "../Header/UserTypeSwitch/UserTypeSwitch";
import { isSpecimenVersion } from "../../utils/init";

export type Props = PropsWithChildren<{
    title: string | ReactNode;
    children?: React.ReactNode;
    breadcrumbs?: React.ReactNode;
    /**
     * URL the backlink should point to. Defaults to using history.goBack
     */
    backTo?: string;
    /**
     * URL the backlink points to if history.goBack has no effect because there
     * is no history
     */
    fallbackBackTo?: string;
    /**
     * Remove chevron icon and backTo link
     * @default false
     */
    noBackBtn?: boolean;
    className?: string;
    /**
     * @default 1
     */
}>;

const PageHeader = ({
    title,
    children,
    className,
    backTo,
    fallbackBackTo,
    noBackBtn,
}: Props) => {
    const history = useHistory();
    const intl = useIntl();
    const { isMobile } = useBreakpoints();

    const ref = useRef<HTMLDivElement>(null);
    const { styles: scrollStyle } = useHideOnScroll(ref);

    return (
        <header
            ref={ref}
            className={cn("page-header", className)}
            style={scrollStyle}
        >
            <div className="page-header__content">
                <div className="page-header__title">
                    {!noBackBtn && (
                        <button
                            onClick={() => {
                                if (backTo) history.push(backTo);
                                else if (history.length <= 1 && fallbackBackTo)
                                    history.push(fallbackBackTo);
                                else history.goBack();
                            }}
                        >
                            <Icon
                                path="chevron_left"
                                size={isMobile ? "medium" : "large"}
                                color="var(--text-main-default)"
                            />
                            <VisuallyHidden as="span">
                                {intl.formatMessage(commonMessages.goBack)}
                            </VisuallyHidden>
                        </button>
                    )}
                    {isReactNode(title) ? title : <h1>{title}</h1>}
                    {isMobile && isSpecimenVersion() && <UserTypeSwitch />}
                </div>
                {isMobile && children && (
                    <div className="page-header__children">{children}</div>
                )}
            </div>

            {!isMobile && children}
        </header>
    );
};
export default PageHeader;
